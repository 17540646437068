import Dialog from "@mui/material/Dialog";

import CloseIcon from "@mui/icons-material/Close";
import {
	DialogTitle,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	styled,
} from "@mui/material";

const Close = styled(IconButton)(({ theme }) => ({
	position: "absolute",
	right: theme.spacing(0.5),
	top: theme.spacing(0.5),
	color: theme.palette.grey[500],
}));

const Container = styled("div")(({ theme }) => ({
	padding: "1.5rem",
}));

const SearchQueryModal = ({ open, onClose, searchQueryData }: { open: any; onClose: any; searchQueryData: any }) => {
	const filterData = Object.values(searchQueryData);
	console.log(filterData);
	return (
		<div>
			<Dialog
				open={Boolean(open)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onClose={onClose}
				fullWidth={true}
				maxWidth="md"
			>
        <DialogTitle id="alert-dialog-title" sx={{
        }}>
					Applied filters
					<Close
						aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: "0.5rem",
              top: "0.5rem",
              color: "#F76C35",
            }}
					>
						<CloseIcon />
					</Close>
				</DialogTitle>
				<Container>
					<TableContainer component={Paper}>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell
										sx={{
											border: 1,
											color: "#F76C35",
											fontWeight: "bold",
										}}
										rowSpan={2}
									>
										filter_id
									</TableCell>
									<TableCell
										sx={{
											border: 1,
											color: "#F76C35",
											fontWeight: "bold",
										}}
										rowSpan={2}
									>
										filter_type
									</TableCell>
									<TableCell
										align="center"
										colSpan={2}
										sx={{
											border: 1,
											color: "#F76C35",
											fontWeight: "bold",
										}}
									>
										location_filter
									</TableCell>
									<TableCell
										align="center"
										colSpan={3}
										sx={{
											border: 1,
											color: "#F76C35",
											fontWeight: "bold",
										}}
									>
										range_filter
									</TableCell>
									<TableCell
										align="center"
										colSpan={2}
										rowSpan={2}
										sx={{ border: 1, color: "#F76C35", fontWeight: "bold" }}
									>
										text_filter
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										align="center"
										sx={{ border: 1, color: "#F76C35", fontWeight: "bold" }}
										colSpan={2}
									>
										title
									</TableCell>
									<TableCell
										align="center"
										sx={{ border: 1, color: "#F76C35", fontWeight: "bold" }}
									>
										min
									</TableCell>
									<TableCell
										align="center"
										sx={{ border: 1, color: "#F76C35", fontWeight: "bold" }}
									>
										max
									</TableCell>
									<TableCell
										align="center"
										sx={{ border: 1, color: "#F76C35", fontWeight: "bold" }}
									>
                    unlock_max
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{filterData.map((row: any, index) => (
									<TableRow key={index}>
										<TableCell sx={{ border: 1 }}>{row.filter_id}</TableCell>
										<TableCell sx={{ border: 1 }}>{row.filter_type}</TableCell>
										<TableCell
											align="center"
											sx={{ border: 1 }}
											colSpan={2}
										>
											{row.location_filter.title}
										</TableCell>
										<TableCell
											align="center"
											sx={{ border: 1 }}
										>
											{row.range_filter.min}
										</TableCell>
										<TableCell
											align="center"
											sx={{ border: 1 }}
										>
											{row.range_filter.max}
										</TableCell>
										<TableCell
											align="center"
											sx={{ border: 1 }}
										>
											{row.range_filter.unlock_max || "N/A"}
										</TableCell>
										{row.text_filter.map((text: any, index: any) => (
											<TableCell
												key={index}
												align="center"
												sx={{ border: 1 }}
											>
												{text}
											</TableCell>
										))}
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Container>
			</Dialog>
		</div>
	);
};

export default SearchQueryModal;
