import { Box, styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";

import { KeyboardArrowDown, MyLocationOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
	Autocomplete,
	CircularProgress,
	DialogTitle,
	FormControl,
	IconButton,
	InputAdornment,
	TextField,
	Typography,
} from "@mui/material";
import {
	GridColDef,
	GridColumnGroupingModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { useLoadScript } from "@react-google-maps/api";
import React from "react";
import usePlacesAutocomplete, { getGeocode, getLatLng, Suggestion } from "use-places-autocomplete";

const Close = styled(IconButton)(({ theme }) => ({
	position: "absolute",
	right: theme.spacing(0.5),
	top: theme.spacing(0.5),
	color: theme.palette.grey[500],
}));

const LocationAutocompleteFormControl = styled(FormControl)(({ theme }) => ({
	backgroundColor: "#f0ecec",
	flex: "0.16666",
	[theme.breakpoints.down("lg")]: {
		flex: "0.25",
	},
	[theme.breakpoints.down("md")]: {
		flex: "0.3333",
	},
	[theme.breakpoints.down("sm")]: {
		flex: "0",
		display: "none",
	},
})) as typeof FormControl;

const LocationAutocomplete = styled(Autocomplete)(({ theme }) => ({
	width: "100%",
})) as typeof Autocomplete;

const LocationAutocompleteRenderedInput = styled(TextField)(({ theme }) => ({
	"& fieldset": { border: "none" },
})) as typeof TextField;

const Container = styled("div")(({ theme }) => ({
	padding: "1.5rem",
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
}));
const OptionText = styled(Typography, {
	shouldForwardProp: (prop) => prop !== "isPrimary",
})<any>(({ theme, isPrimary }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	fontSize: "0.875rem",
	lineHeight: "1.3125rem",
	fontWeight: 400,
	color: isPrimary ? theme.palette.primary.main : theme.palette.text.primary,
})) as any;
const OptionContainer = styled("li")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-start",
	width: "100%",
	padding: "0.25rem 0.5rem",
}));

const OptionSubtext = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	fontSize: "0.875rem",
	lineHeight: "1.3125rem",
	fontWeight: 400,
	color: theme.palette.text.secondary,
})) as typeof Typography;

type loadableLibraries = Array<"places" | "drawing" | "geometry" | "localContext" | "visualization">;

interface GMRGCResultsAddressComponent {
	long_name: string;
	short_name: string;
	types: Array<string>;
}

/*

& Next, let's declare any static constants we intend to use on this page outside the functional component.

*/

const librariesToLoad: loadableLibraries = ["places"];

const InventoryModal = ({ open, onClose, bugdata }: { open: any; onClose: any; bugdata: any }) => {
	const [allowUserInputInLocationAutocomplete, setAllowUserInputInLocationAutocomplete] =
		React.useState<boolean>(false);

	const [userLocationAllowed, setUserLocationAllowed] = React.useState<boolean>(false);

	const [locationCoordinates, setLocationCoordinates] = React.useState<Array<number>>([12.9117856, 77.6407384]);

	const [inventoryData, setInventoryData] = React.useState<any>([]);

	const [columnGroupingModel, setColumnGroupingModel] = React.useState<GridColumnGroupingModel>([]);
	const [columns, setColumns] = React.useState<GridColDef[]>([]);
	const [rows, setRows] = React.useState<any[]>([]);
	const [totalCount, setTotalCount] = React.useState<number>(0);

	const {
		value: locationAutocompleteValue,
		suggestions: { loading: locationAutocompleteSuggestionsLoading, data: locationAutocompleteSuggestions },
		setValue: setLocationAutocompleteValue,
		init: initializeLocationAutocomplete,
	} = usePlacesAutocomplete({
		initOnMount: false,
		debounce: 500,
		defaultValue: "",
	});

	/*

    & Next, let's load the required Google Maps libraries.

    */

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY || "",
		libraries: librariesToLoad as any,
	});

	/*

    & Next, let's describe a function to figure out the user's current location.

    */

	const getCurrentLocation = React.useCallback(() => {
		/*

        & Next, let's check if the user has allowed us to use their location and update the corresponding state accordingly.

        */

		if (!navigator.geolocation) {
			setUserLocationAllowed(false);
		} else {
			/*

            & Next, since it looks like the user has allowed us to use their location, let's get their current location. Let's begin by disabling the location autocomplete input.

            */

			setAllowUserInputInLocationAutocomplete(true);

			navigator.geolocation.getCurrentPosition(
				async (position: GeolocationPosition) => {
					/*

                    & Next, let's extract the latitude and longitude from the position object.

                    */

					const { latitude, longitude }: { latitude: number; longitude: number } = position.coords;

					/*

                    & Next, let's use Google Maps' Reverse Geocoding API to get the user's current address.

                    */

					const googleMapsReverseGeocodingApiResponse: any = await (
						await fetch(
							"https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
								latitude +
								"," +
								longitude +
								"&key=" +
								(process.env.GOOGLE_MAPS_API_KEY || ""),
							{ cache: "no-store" },
						)
					).json();

					/*

                    & Next, let's extract the user's current address from the API response object.

                    */

					const userCurrentAddress: string | undefined =
						googleMapsReverseGeocodingApiResponse?.results[0]?.address_components?.find(
							(address_component: GMRGCResultsAddressComponent) =>
								address_component.types.includes("sublocality_level_1"),
						)?.long_name;

					const userCurrentAreas: Array<string> | undefined = googleMapsReverseGeocodingApiResponse?.results.map(
						(result: any) => {
							return result.address_components?.find(
								(address_component: GMRGCResultsAddressComponent) =>
									address_component.types.includes("sublocality_level_1") ||
									address_component.types.includes("locality"),
							)?.long_name as string;
						},
					);

					let userCurrentArea: string | undefined;

					let resultPos: number = 0;
					if (userCurrentAreas) {
						for (let i = 0; i < userCurrentAreas.length; i++) {
							if (userCurrentAreas[i] !== "Bengaluru") {
								userCurrentArea = userCurrentAreas[i];
								resultPos = i;
								break;
							}
						}
					}

					/*

                    & Next, let's update the corresponding state with the user's current address. Let's also set the userLocationAllowed state to true and the allowUserInputInLocationAutocomplete state to false.

                    */

					setLocationAutocompleteValue(userCurrentAddress ? userCurrentAddress : "");
					const gg = await fetch("/api/property-table/property-inventory", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({
							location: [latitude, longitude],
						}),
					}).then((response) => response.json());

					setInventoryData(gg.payload ? gg.payload.property_types : []);
					setTotalCount(gg.payload ? gg.payload.total_properties : 0);

					setLocationCoordinates([latitude, longitude]);
					// setUserLocationAllowed(true);
					setAllowUserInputInLocationAutocomplete(false);
				},
				async (error) => {
					console.error(error);
				},
				{ timeout: 10000, enableHighAccuracy: true },
			);
		}
	}, [setLocationAutocompleteValue]);

	/*

    & Next, let's write a useEffect hook to check if the Google Maps libraries have loaded. If they have, let's initialise the location autocomplete hook. Let's also try to obtain the user's current location.

    */

	React.useEffect(() => {
		if (isLoaded) {
			initializeLocationAutocomplete();

			/*

            & Next, let's try to obtain the user's current location. We'll only do this if the query string doesn't already contain a location.

            */

			// getCurrentLocation();
		}
	}, [isLoaded, initializeLocationAutocomplete]);

	/*

    & Next, let's describe an onChange handler for the location autocomplete component.

    */

	const onChangeLocationAutocomplete = async (
		event: React.SyntheticEvent<HTMLSelectElement>,
		value: string,
	): Promise<void> => {
		if (value === "Use current location") {
			getCurrentLocation();
		} else {
			setLocationAutocompleteValue(value);
			const { lat: tempLat, lng: tempLng } = getLatLng((await getGeocode({ address: value }))[0]);
			const gg = await fetch("/api/property-table/property-inventory", {
				method: "POST",
				headers: {
					"Content-Type": "text/plain",
				},
				body: JSON.stringify({
					location: [tempLat, tempLng],
				}),
			}).then((response) => response.json());

			setInventoryData(gg.payload ? gg.payload.property_types : []);
			setTotalCount(gg.payload ? gg.payload.total_properties : 0);

			setLocationCoordinates([tempLat, tempLng]);
			// await dispatch(setUserLocationThunk(value));
		}
	};

	const onChangeLocationAutocompleteRenderedInput = async (
		event: React.ChangeEvent<HTMLInputElement>,
	): Promise<void> => {
		// if (event.target.value === "") {
		// 	const temp: StateInterface["home_location"] = {
		// 		name: "",
		// 		location: [12.9716, 77.5946],
		// 	};
		// 	// await dispatch(setHomeLocationThunk(temp));
		// }
		setLocationAutocompleteValue(event.target.value);
		const { lat: tempLat, lng: tempLng } = getLatLng((await getGeocode({ address: event.target.value }))[0]);
	};

	const onClickGetCurrentLocationButton: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void = async (
		event: React.MouseEvent<HTMLButtonElement>,
	) => {
		if (userLocationAllowed) {
			getCurrentLocation();
		} else {
			setUserLocationAllowed(true);
		}
	};

	React.useEffect(() => {
		// Fetch data from the backend
		if (inventoryData.length > 0) {
			console.log(inventoryData.property_types, "inventoryData");

			// Collect all unique property_types and categories
			const allPropertyTypes = new Set();
			const allCategories = new Set();

			inventoryData.forEach((row: any) => {
				row.property_types.forEach((type: any) => {
					allPropertyTypes.add(type.property_type);
				});
				row.categories.forEach((category: any) => {
					allCategories.add(category.property_type);
				});
			});

			// Convert sets to arrays
			const propertyTypeFields = Array.from(allPropertyTypes);
			const categoryFields = Array.from(allCategories);

			// Add unique id to each row and flatten property_types and categories
			const rows = inventoryData.map((row: any, index: number) => {
				const propertyTypes = row.property_types.reduce((acc: any, type: any) => {
					acc[type.property_type] = type.count;
					return acc;
				}, {});

				const categories = row.categories.reduce((acc: any, category: any) => {
					acc[category.property_type] = category.count;
					return acc;
				}, {});

				return {
					id: index,
					...row,
					...propertyTypes,
					...categories,
				};
			});

			// Construct the columns based on the unique property_types and categories
			const columns: GridColDef[] = [
				{ field: "transaction_type", headerName: "Transaction Type", width: 200 },
				{ field: "transaction_type_count", headerName: "Transaction Type Count", width: 200 },
				...propertyTypeFields.map((field: string) => ({
					field,
					headerName: field.replace(/_/g, " ").toUpperCase(),
					width: 200,
				})),
				...categoryFields.map((field: string) => ({
					field,
					headerName: field.replace(/_/g, " ").toUpperCase(),
					width: 200,
				})),
			];

			// Construct the column grouping model based on the unique property_types and categories
			const columnGroupingModel: GridColumnGroupingModel = [
				{
					groupId: "property_types",
					headerName: "Property Types",
					children: propertyTypeFields.map((field: string) => ({ field })),
				},
				{
					groupId: "categories",
					headerName: "Categories",
					children: categoryFields.map((field: string) => ({ field })),
				},
			];

			setColumns(columns);
			setColumnGroupingModel(columnGroupingModel);
			setRows(rows);
		}
	}, [inventoryData]);

	const GridToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarFilterButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarDensitySelector
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarExport
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
			</GridToolbarContainer>
		);
	};

	return (
		<div>
			<Dialog
				open={Boolean(open)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onClose={() => {
					onClose();
					setInventoryData([]);
				}}
				fullWidth={true}
				fullScreen={true}
			>
				<DialogTitle
					id="alert-dialog-title"
					sx={{
						color: "primary.main",
					}}
				>
					Inventory Details
					<Close
						aria-label="close"
						onClick={() => {
							onClose();
							setInventoryData([]);
						}}
					>
						<CloseIcon />
					</Close>
				</DialogTitle>

				<Container>
					<LocationAutocompleteFormControl fullWidth={true}>
						<LocationAutocomplete
							id={"location-autocomplete"}
							freeSolo={false}
							disabled={allowUserInputInLocationAutocomplete}
							disableClearable={true}
							autoComplete={true}
							autoSelect={true}
							selectOnFocus={true}
							ListboxProps={{
								style: {
									backgroundColor: "#FFFFFF",
								},
							}}
							options={[
								"Use current location",
								// locationAutocompleteValue,
								/*
                ^ This line adds whatever the user types as a search autocomplete option.Comment it if freeSolo is false.
                */
								...locationAutocompleteSuggestions.map((suggestion: Suggestion) => {
									/*

                                    & Next, in this map, let's look for the current location's string so we don't encounter string mismatch warnings when using the MUI Autocomplete component.

                                    */

									const term = suggestion.terms.find((term: any) => term.value === locationAutocompleteValue);

									return term ? term.value : suggestion.structured_formatting.main_text;
								}),
							].filter((locationAutocompleteSuggestion: string, position: number, self: Array<string>) => {
								/*

                                    & Next, since we included the current location's string in the array, let's filter it out if it's an empty string. Let's also ensure that we are not returning any duplicate suggestions.

                                    */

								return (
									locationAutocompleteSuggestion !== "" && self.indexOf(locationAutocompleteSuggestion) === position
								);
							})}
							isOptionEqualToValue={(option: string, value: string) => {
								/*

                                & Next, when the user erases everything in the LocationAutocompleteRenderedInput component, let's return true so that the LocationAutocomplete component doesn't warn us about a string mismatch.

                                */

								return value === "" ? true : option === value;
							}}
							value={locationAutocompleteValue}
							onChange={onChangeLocationAutocomplete}
							popupIcon={<KeyboardArrowDown />}
							renderOption={(props, option, state, ownerState) => {
								const las: any = locationAutocompleteSuggestions.find(
									(suggestion: any) => suggestion.structured_formatting.main_text === option,
								);
								const optionSubtext: string | undefined =
									option === "Use current location"
										? "Use current location"
										: las
											? las.structured_formatting.secondary_text
											: undefined;
								return (
									<OptionContainer {...props}>
										<OptionText
											variant="body1"
											component="span"
											isPrimary={option === "Use current location"}
										>
											{option}
										</OptionText>
										<OptionSubtext
											variant="body2"
											component="span"
										>
											{optionSubtext
												? optionSubtext === "Use current location"
													? "Use GPS or IP address to locate me"
													: optionSubtext
												: "in the middle of nowhere"}
										</OptionSubtext>
									</OptionContainer>
								);
							}}
							renderInput={(params: any) => (
								<LocationAutocompleteRenderedInput
									{...params}
									label={"Enter a location"}
									onChange={onChangeLocationAutocompleteRenderedInput}
									InputProps={{
										...params.InputProps,
										startAdornment: (
											<InputAdornment position="start">
												{locationAutocompleteSuggestionsLoading || allowUserInputInLocationAutocomplete ? (
													<CircularProgress size={"1.5rem"} />
												) : (
													<IconButton
														aria-label="Use my current location"
														color="primary"
														onClick={onClickGetCurrentLocationButton}
													>
														<MyLocationOutlined />
													</IconButton>
												)}
											</InputAdornment>
										),
									}}
									placeholder="Enter a location"
								/>
							)}
						/>
					</LocationAutocompleteFormControl>
					<Box sx={{ height: "80vh", padding: " 1rem", width: "100%" }}>
						{/* <Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell
										align="center"
										sx={{ border: 1 }}
									>
										Transaction Type
									</TableCell>
									<TableCell
										align="center"
										sx={{ border: 1 }}
									>
										Transaction Type Count
									</TableCell>
									{inventoryData[0]?.property_types.map((type: any, typeIndex: number) => (
										<TableCell
											key={`header-type-${typeIndex}`}
											align="center"
											sx={{ border: 1 }}
										>
											{type.property_type}
										</TableCell>
									))}
									{inventoryData[0]?.categories.map((category: any, categoryIndex: number) => (
										<TableCell
											key={`header-category-${categoryIndex}`}
											align="center"
											sx={{ border: 1 }}
										>
											{category.property_type}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{inventoryData.map((row: any, index: number) => (
									<TableRow key={index}>
										<TableCell
											align="center"
											sx={{ border: 1 }}
										>
											{row.transaction_type}
										</TableCell>
										<TableCell
											align="center"
											sx={{ border: 1 }}
										>
											{row.transaction_type_count}
										</TableCell>
										{row.property_types.map((type: any, typeIndex: number) => (
											<TableCell
												key={`type-${typeIndex}`}
												align="center"
												sx={{ border: 1 }}
											>
												{type.count}
											</TableCell>
										))}
										{row.categories.map((category: any, categoryIndex: number) => (
											<TableCell
												key={`category-${categoryIndex}`}
												align="center"
												sx={{ border: 1 }}
											>
												{category.count}
											</TableCell>
										))}
									</TableRow>
								))}
							</TableBody>
						</Table> */}
						{inventoryData.length > 0 ? (
							<>
								<Typography
									variant="h6"
									sx={{ marginBottom: "1rem" }}
								>
									{inventoryData.length > 0 ? `Total Properties: ${totalCount}` : ""}
								</Typography>
								<DataGridPremium
									slots={{ toolbar: GridToolbar }}
									rows={rows}
									columns={columns}
									getRowId={(row) => row.id} // Specify custom id for each row
									pagination
									pageSizeOptions={[100]}
								/>
							</>
						) : (
							<Typography
								variant="h6"
								sx={{ marginBottom: "1rem" }}
							>
								No data available
							</Typography>
						)}
					</Box>
				</Container>
			</Dialog>
		</div>
	);
};

export default InventoryModal;
