/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

& Next, let's describe the type for extra props used in the Container Component

*/

type ExtraPropsForContainerComponent = {
  unboundBackground: string;
  gap: string;
};

/*

& Next, let's describe all the styled components we need

*/

const ContainerComponent = styled("div", {
  shouldForwardProp: (prop) => prop !== "unboundBackground" && prop !== "gap",
})<ExtraPropsForContainerComponent>(({ theme, unboundBackground, gap }) => ({
  /* XXS breakpoint */
  [theme.breakpoints.only("xxs")]: {
    padding: "0rem 0.5rem 0rem 0.5rem",
  },
  /* XS breakpoint */
  [theme.breakpoints.only("xs")]: {
    padding: "0rem 1rem 0rem 1rem",
  },
  /* XSPLUS breakpoint */
  [theme.breakpoints.only("xsPlus")]: {
    padding: "0rem 1.5rem 0rem 1.5rem",
  },
  /* SM breakpoint */
  [theme.breakpoints.only("sm")]: {
    padding: "0rem 3rem 0rem 3rem",
  },
  /* SMPLUS breakpoint */
  [theme.breakpoints.only("smPlus")]: {
    padding: "0rem 3rem 0rem 3rem",
  },
  /* SM822 breakpoint */
  [theme.breakpoints.only("sm822")]: {
    padding: "0rem 3rem 0rem 3rem",
  },
  /* SM860 breakpoint */
  [theme.breakpoints.only("sm860")]: {
    padding: "0rem 3rem 0rem 3rem",
  },
  /* SM910 breakpoint */
  [theme.breakpoints.only("sm910")]: {
    padding: "0rem 3rem 0rem 3rem",
  },
  /* MD breakpoint */
  [theme.breakpoints.only("md")]: {
    padding: "0rem 4rem 0rem 4rem",
  },
  /* MD1190 breakpoint */
  [theme.breakpoints.only("md1190")]: {
    padding: "0rem 4rem 0rem 4rem",
  },
  /* MD1220 breakpoint */
  [theme.breakpoints.only("md1220")]: {
    padding: "0rem 4rem 0rem 4rem",
  },
  /* LG breakpoint */
  [theme.breakpoints.only("lg")]: {
    padding: "0rem 4rem 0rem 4rem",
  },
  /* LGPLUS breakpoint */
  [theme.breakpoints.up("lgPlus")]: {
    padding: "0rem 5rem 0rem 5rem",
  },
  /* XL breakpoint */
  [theme.breakpoints.up("xl")]: {
    padding: "0rem 23rem 0rem 23rem",
  },
  /* XXL breakpoint */
  [theme.breakpoints.up("xxl")]: {
    padding: "0rem 36rem 0rem 36rem",
  },
  /* XXXL breakpoint */
  [theme.breakpoints.up("xxxl")]: {
    padding: "0rem 72rem 0rem 72rem",
  },
  backgroundColor: unboundBackground,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: gap,
}));

/*

& Next, let's describe the component

*/

const Container = ({
  children,
  unboundBackground,
  containerize,
  gap,
}: {
  children?: React.ReactNode;
  unboundBackground?: string;
  containerize?: boolean;
  gap?: string;
}) => {
  return (
    <React.Fragment>
      {containerize ? (
        <ContainerComponent
          unboundBackground={unboundBackground || ""}
          gap={gap || "1rem"}
        >
          {children}
        </ContainerComponent>
      ) : (
        <div style={{ background: unboundBackground || "" }}>{children}</div>
      )}
    </React.Fragment>
  );
};

/*

& Finally, let's export the component as default

*/

export default Container;
