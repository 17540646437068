/* Common import */
import React from "react";

//@mui imports

import { styled } from "@mui/system";

/* component import */

import MenuContent from "./sub-components/MenuContent";
// import CollapsedMenu from "./sub-components/CollapsedMenu";
// import AnalyticsGraph from "./Analytics/analyticsGraph";
// import UserActivitytab from "./Analytics/sub-component/UserActivityTab";
// import AnalyticsSearchResultsMain from "./Analytics/SearchResultMain";
import ActivityDatatable from "./Activity_log";
import BeegruPointsTransactionsDataTable from "./BeegruPointsTransactionsTable";
import BugDatatable from "./ModerationDashboard/Bug/BugDatatable";
import BusinessDevelopmentDataTable from "./ModerationDashboard/Business-development/BusinessDevelopmentDataTable";
import BusinessProfilesDatatable from "./ModerationDashboard/Business-development/BusinessProfilesDataTable";
import EmployeesDatatable from "./ModerationDashboard/Employees";
import IAMDatatable from "./ModerationDashboard/IAM/IAMDatatable";
import BusinessKycDataTable from "./ModerationDashboard/Kyc/BusinessKycDataTable";
import UserKycDataTable from "./ModerationDashboard/Kyc/UserKycDataTable";
import PropertyDataTable from "./ModerationDashboard/Moderation/PropertyDataTable";
import ServiceDataTable from "./ModerationDashboard/Moderation/ServiceDataTable";
import PostsDatatable from "./ModerationDashboard/Posts/postDataTable";
import ReportDataTable from "./ModerationDashboard/Reports/ReportDatatable";
import SubscriptionsDatatable from "./ModerationDashboard/Subscriptions/subscriptionstable";
import UsertDatatable from "./ModerationDashboard/User-management/UsertDatatable";
// import ManualBeegruPointsTransactionsDataTable from "./ManualBeegruPointsTransactions";
import { useRouter } from "next/router";
import BoostPricingManualOverridesDataTable from "./boostPricingManualOverrides";
import CashTransactionsDataTable from "./CashTransaction";
import LogsDataTable from "./LogsTable";
import PropertyStatusDataTable from "./propertyStatusTable";
import SearchLogDataTable from "./Search_log";

/* Redux import */

/* Styling */

type TableContainerProps = {
	collapseState: boolean;
};

const ContentContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
}));

const MenuContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
}));

const TableContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "collapseState",
})<TableContainerProps>(({ theme, collapseState }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "calc(100% - " + (collapseState ? "80px" : "20rem") + ")",
}));

const HomeDashboard = ({
	containerize,
	unboundBackground,
	gap,
	DashboardData,
	session,
}: {
	containerize?: boolean;
	unboundBackground?: string;
	gap?: string;
	DashboardData: any;
	session: any;
}) => {
	// const [selectedList, setSelectedList] = React.useState("property");

	/*

  & Let's declare the router. Here we are using the useRouter hook from nextjs.

  & We are using the router to get the query params from the url. Memoize the router object to prevent unnecessary re-renders.

  */

	const router = useRouter();

	const handleListItemClick = (event: any, name: string) => {
		// setSelectedList(name);
		router.push(
			{
				pathname: router.pathname,
				query: { tab: name },
			},
			undefined,
			{
				shallow: true,
			},
		);
	};

	const dataToRender: Function = (selectedList: string): any => {
		switch (selectedList) {
			case "property":
				return (
					<PropertyDataTable
						DashboardData={DashboardData}
						session={session}
					/>
				);
			case "service":
				return (
					<ServiceDataTable
						DashboardData={DashboardData}
						session={session}
					/>
				);
			case "business-dev":
				return (
					<BusinessDevelopmentDataTable
						DashboardData={DashboardData}
						session={session}
					/>
				);
			case "user-kyc":
				return (
					<UserKycDataTable
						DashboardData={DashboardData}
						session={session}
					/>
				);
			case "business-kyc":
				return (
					<BusinessKycDataTable
						DashboardData={DashboardData}
						session={session}
					/>
				);
			// case "analytics":
			//   return (
			//     <AnalyticsMain DashboardData={DashboardData} session={session} />
			//   );
			case "iam":
				return (
					<IAMDatatable
						DashboardData={DashboardData}
						session={session}
					/>
				);
			case "user-mgmnt":
				return (
					<UsertDatatable
						DashboardData={DashboardData}
						session={session}
					/>
				);
			case "bug-report":
				return (
					<BugDatatable
						DashboardData={DashboardData}
						session={session}
					/>
				);
			case "report":
				return (
					<ReportDataTable
						DashboardData={DashboardData}
						session={session}
					/>
				);
			case "subscriptions":
				return (
					<SubscriptionsDatatable
						DashboardData={DashboardData}
						session={session}
					/>
				);
			case "business-profiles":
				return (
					<BusinessProfilesDatatable
						DashboardData={DashboardData}
						session={session}
					/>
				);

			case "post":
				return (
					<PostsDatatable
						DashboardData={DashboardData}
						session={session}
					/>
				);
			case "employees":
				return (
					<EmployeesDatatable
						DashboardData={DashboardData}
						session={session}
					/>
				);

			case "activity_log":
				return (
					<ActivityDatatable
						DashboardData={DashboardData}
						session={session}
					/>
				);
			case "beegru_points_transaction":
				return (
					<BeegruPointsTransactionsDataTable
						DashboardData={DashboardData}
						session={session}
					/>
				);
			// case "manual_points_transaction":
			//   return (
			//     <ManualBeegruPointsTransactionsDataTable
			//       DashboardData={DashboardData}
			//       session={session}
			//     />
			//   );

			case "cash_transaction":
				return (
					<CashTransactionsDataTable
						DashboardData={DashboardData}
						session={session}
					/>
				);
			case "search_logs":
				return (
					<SearchLogDataTable
						DashboardData={DashboardData}
						session={session}
					/>
				);

			case "boost_pricing_manual_overrides":
				return (
					<BoostPricingManualOverridesDataTable
						DashboardData={DashboardData}
						session={session}
					/>
				);
			case "logs":
				return (
					<LogsDataTable
						DashboardData={DashboardData}
						session={session}
					/>
				);
			case "latest-changes":
				return (
					<PropertyStatusDataTable
						DashboardData={DashboardData}
						session={session}
					/>
				);

			default:
				break;
		}
	};

	const [collapsed, setCollapsed] = React.useState<boolean>(false);

	return (
		<ContentContainer>
			<MenuContainer>
				<MenuContent
					session={session}
					selectedList={router.query.tab as string}
					handleListItemClick={handleListItemClick}
					collapseState={(state: boolean) => {
						setCollapsed(state);
					}}
				/>
			</MenuContainer>
			<TableContainer collapseState={collapsed}>{dataToRender(router.query.tab)}</TableContainer>
		</ContentContainer>
	);
};

export default HomeDashboard;
